export const SERVER_BASE_URL = "https://api.ozapp.it/";//"https://demo.ozapp.it/";//
//export const SERVER_BASE_URL = "http://localhost:8080/";

export const page = 0;
export const size = 15;

export const pageSize1 = 15;
export const pageSize2 = 30;
export const pageSize3 = 50;
export const pageSize4 = 100;

/*etichetta aperta-chiusa per le notifiche*/
export const labelOpen ="Aperta";
export const labelClose ="Chiusa";

/*elementi tabella Notifiche*/
export const livello = "Livello";
export const elemento = "Elemento";
export const messaggio = "Messaggio";
export const status = "Status";
export const dataApertura = "Data apertura";
export const dataChiusura = "Data chiusura";
export const azione = "Azione";
export const segnala = "Segnala";

/*label ruoli: admin, user*/
export const admin = "ADMIN";
export const user = "USER";

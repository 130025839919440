import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_BASE_URL } from '../constants';
import { Configuration } from '../model/configuration';
import { AbstractService } from './abstractservice';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends AbstractService<Configuration, number> {

  constructor(protected http: HttpClient) { 
    super(http);
    this.type = 'configuration';
  }

  searchByLabel(label: String){
    return this.http.get<Configuration>(SERVER_BASE_URL+'configuration/findbylabel/' + label);
  }
}

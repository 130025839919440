import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { page, pageSize1, pageSize2, pageSize3, pageSize4, size } from '../constants';
import { Access } from '../model/access';
import { AccessService } from '../service/access.service';

var ACCESS: Access[] = [];

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.css', '../../style.css']
})
export class AccessComponent implements OnInit {

  dateStart: Date;
  dateEnd: Date;
  
  access: Access[];
  numberofaccess: number;

  page = page;
  size = size;

  pageSize1 = pageSize1;
  pageSize2 = pageSize2;
  pageSize3 = pageSize3;
  pageSize4 = pageSize4;
  

  check: boolean;

  format = 'dd-MM-yyyy';
  locale = 'en-US';

  constructor(private accessService: AccessService) { }

  ngOnInit(): void {
  }


  getAccess(start: Date, end: Date,page,size){
    
    const formattedStart = formatDate(start, this.format, this.locale);
    const formattedEnd = formatDate(end, this.format, this.locale);
    console.log("inizio e fine", formattedStart, formattedEnd);
    this.accessService.getAccess(formattedStart, formattedEnd,page,size).subscribe(access => {
      this.access = access.content;
      this.numberofaccess = access.totalElements;
      // ACCESS = this.access;
      this.check = true;
      // console.log(this.access);
    })
  }

  getNumberOfAccess(start: string, end: string){
    this.accessService.getNumberOfAccess(start, end).subscribe(access => {
      this.numberofaccess = access
      // console.log(this.numberofaccess);
    });
  }



}

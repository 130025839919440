import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVER_BASE_URL } from '../constants';
import { RecoveryPassword } from '../model/recoverypsw';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  lang : String = "it";

  constructor(protected http: HttpClient) { }

  submitEmail(email: String): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL + "auth/passwordRecovery/request/" + email + '/?lang=' + this.lang);
  }

  resendEmail(email: String): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL + "auth/passwordRecovery/resend/" + email + '/?lang=' + this.lang);
  }

  recoveryPassw(t: RecoveryPassword): Observable<any> {
    return this.http.post<any>(SERVER_BASE_URL + "auth/passwordRecovery/set", t);
  }

}

import { Component, OnInit } from '@angular/core';
import { ChartSize } from 'chart.js';
import { Observable } from 'rxjs';
import { page, pageSize1, pageSize2, pageSize3, pageSize4, size } from '../constants';
import { Configuration } from '../model/configuration';
import { ConfigurationService } from '../service/configuration.service';


@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css','../../style.css']
})
export class ConfigurationComponent implements OnInit {

  configurations: Configuration[];

  totalConfigurations: number;

  page = page;
  size = size;

  pageSize1 = pageSize1;
  pageSize2 = pageSize2;
  pageSize3 = pageSize3;
  pageSize4 = pageSize4;

  constructor(private configurationService: ConfigurationService) { 

  }

  ngOnInit(): void {
    this.findAllConfiguration(page,size);
  }

  findAllConfiguration(page,size) {
    this.configurationService.findAllPaginate(page,size).subscribe(configuration => {
      this.configurations = configuration.content;
      this.totalConfigurations = configuration.totalElements;
      console.log(this.configurations);
    });
  }

 

}

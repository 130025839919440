import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css']
})
export class ToggleButtonComponent  {
  @Input() checked: boolean;
  @Input() titleChecked: string;
  @Input() titleUnChecked: string;
  @Input()  dataTarget: string;
  @Output() changed = new EventEmitter<boolean>();


  check(checked:boolean){
    this.checked = checked;
    this.changed.emit(checked);
  }

}

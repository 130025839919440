import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVER_BASE_URL } from '../constants';
import { Juror, JurorId } from '../model/juror';
import { AbstractService } from './abstractservice';

@Injectable({
  providedIn: 'root'
})
export class JurorService extends AbstractService<Juror | JurorId, number>{

  constructor(http: HttpClient) { 
    super(http);
    this.type = "participant";
  }

  findJurors(id: number): Observable<Juror[]> {
    return this.http.get<Juror[]>(SERVER_BASE_URL + 'contest/' + id + '/jurors');
  }

  deleteParticipant(id: number): Observable<JurorId> {
    return this.http.delete<JurorId>(SERVER_BASE_URL + this.type + '/delete/' + id, {headers: this.headerOpt});
  }
}

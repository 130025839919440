import {AbstractService} from './abstractservice';
import {Notification} from '../model/notification';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { SERVER_BASE_URL } from '../constants';

/**
 * MediaNotificationService
 *
 * @file Service used to retrieve media information
 * @author Cosmin Fane Cozma <cosmin.cozma@micegroup.it>
 */
@Injectable({providedIn: 'root'})
export class MediaNotificationsService extends AbstractService<Notification, number> {

  /**
   * MediaNotifications Service constructor.
   * @param client: HttpClient
   */
  public constructor(client: HttpClient) {
    super(client);
    this.type = 'notification';
  }

  public filterByMedia(id: number,page,size): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL + this.type + '/filter-by/media/' + id + '/?page=' + page + '&size=' + size);
  }

  public filterByComment(id: number): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL + this.type + '/filter-by/comment/' + id);
  }

  filterNotificationByUserId(id: number, page, size): Observable<any>{
    return this.http.get<any>(SERVER_BASE_URL+'notification/filter-by/user/' + id + '/?page=' + page + '&size=' + size);
  }

  filterNotificationReceivedByUserId(id: number, page, size): Observable<any>{
    return this.http.get<any>(SERVER_BASE_URL+'notification/filter-by/user-notified/' + id + '/?page=' + page + '&size=' + size);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  constructor() { }


  @Input() idDiaolog: string;
  @Input() title: string;
  @Input() message: string;
  @Input() buttons: 'ok' | 'sino' |'okcancel' = 'ok';
  @Output() onOkPress = new EventEmitter();
  @Output() onCancelPress = new EventEmitter();

  ngOnInit(): void {
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from '../model/category';
import { AbstractService } from './abstractservice';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends AbstractService<Category, number>{

  constructor(http: HttpClient) { 
    super(http);
    this.type = "category";
  }
}

import { AbstractService } from './abstractservice';
import { Media } from '../model/media';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_BASE_URL } from '../constants';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Moderation } from '../model/moderation';

/**
 * MediaDetailsService
 *
 * @file Service used to retrieve media information
 * @author Cosmin Fane Cozma <cosmin.cozma@micegroup.it>
 */
@Injectable({ providedIn: 'root' })
export class MediaDetailService extends AbstractService<Media, number> {
  /**
   * MediaDetails Service constructor.
   * @param client: HttpClient
   */
  public constructor(client: HttpClient) {
    super(client);
    this.type = 'media';
  }

  countNotification() {
    return this.http.get<any>(SERVER_BASE_URL + 'notification/false/count');
  }

  blockMedia(id: number): Observable<any> {
    return this.http.delete<any>(SERVER_BASE_URL + 'media/block/' + id);
  }

  unblockMedia(t: Media, id: number): Observable<any> {
    return this.http.post<any>(SERVER_BASE_URL + 'media/unblock/' + id, t);
  }

  deleteMedia(id: number): Observable<any> {
    return this.http.delete<any>(SERVER_BASE_URL + 'media/delete/' + id);
  }

  getModerations(idMedia: number): Observable<Moderation> {
    return this.http
      .get<Moderation>(SERVER_BASE_URL + 'moderation/' + idMedia)
      .pipe(catchError(super.aahandleErrorOzapp));
  }
}

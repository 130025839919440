import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UIService } from './service/ui.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private msg: UIService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // debugger;
    const started = Date.now();
    this.msg.startIntercept(req.url, 'Attendere...');
    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            this.msg.stopIntercept(req.url);
            const elapsed = Date.now() - started;
            console.log(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
          }
        },
        (err) => {
          this.msg.stopIntercept(req.url);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate([
                '/externalRedirect',
                { externalUrl: 'environment.loginUrl' },
              ]);
            }
          }
        }
      )
    );
  }
}

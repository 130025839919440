import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Contest } from '../model/contest';
import { ContestService } from '../service/contest.service';
import { NgbdSortableHeader, SortEvent } from '../sortable.directive';
import { page, size, pageSize4, pageSize3, pageSize2, pageSize1 } from '../constants';

@Component({
  selector: 'app-contest',
  templateUrl: './contest.component.html',
  styleUrls: ['./contest.component.css', '../../style.css'],
  providers: [ContestService, DecimalPipe]
})
export class ContestComponent implements OnInit {

  
  defaultPage = page;
  defaultSize = size;
  pageCanto = page;
  sizeCanto = size;
  pageRecitazione = page;
  sizeRecitazione = size;
  pageRegia = page;
  sizeRegia = size;
  pageSearch = page;
  sizeSearch = size;
  pageSize1 = pageSize1;
  pageSize2 = pageSize2;
  pageSize3 = pageSize3;
  pageSize4 = pageSize4;


  searchResult: Contest[];
  searchResultTotal=0;
  contestsinging: Contest[];
  contestsingingTotal=1;
  contestrecitation: Contest[]; 
  contestrecitationTotal=1;
  contestdirecting: Contest[];
  contestdirectingTotal=1;
  search = '';

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private contestService: ContestService) {
   }

  ngOnInit(): void {
    this.findContestSinging(this.pageCanto,this.sizeCanto);
    this.findContestRecitation(this.pageRecitazione,this.sizeRecitazione);
    this.findContestDirecting(this.pageRegia,this.sizeRegia);
  }

  reload() {
    window.location.reload();
  }


  searchContest(nome,page,size){
    this.contestService.searchContest(nome,page,size).subscribe(result => {
      this.searchResult = result.content;
      this.searchResultTotal = result.totalElements;
      console.log("Contest Search: ",this.searchResult);
    });
  }


  findContestSinging(page,size) {
    this.contestService.findSinging(false,page,size).subscribe(contestsinging => {
      this.contestsinging = contestsinging.content;
      this.contestsingingTotal = contestsinging.totalElements;
      //console.log("Contest CANTO: ",this.contestsinging);
    });

  }

  findContestRecitation(page,size) {
    this.contestService.findRecitation(false, page, size).subscribe(contestrecitation => {
      this.contestrecitation = contestrecitation.content;
      this.contestrecitationTotal = contestrecitation.totalElements;
      //console.log(this.contestrecitation);
    });
  }

  findContestDirecting(page,size) {
    this.contestService.findDirecting(false, page, size).subscribe(contestdirecting => {
      this.contestdirecting = contestdirecting.content;
      this.contestdirectingTotal = contestdirecting.totalElements;
      //console.log(this.contestdirecting);
    });
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
  }

  
  onSortRecitation({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
  }

  onSortDirecting({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
  }

  onSearchChange(searchValue: string): void {  
    //console.log(searchValue);
    if(searchValue.length==0){
      this.searchResultTotal=0;
    }
  }


}

import {Component, Input, OnInit} from '@angular/core';
import {Comment} from '../model/comment';
import {MediaNotificationsService} from '../service/media-notification.service';
import {Notification} from '../model/notification';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {

  @Input() comment: Comment;
  @Input() index: number;

  expanded: boolean = false;

  notifications: Notification[];

  public constructor(private notificationService: MediaNotificationsService) {
  }

  ngOnInit(): void {
  }

  collapse(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.notificationService.filterByComment(this.comment.commentId).subscribe((notifications: Notification[]) => {
        this.notifications = notifications;
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UIService {
  constructor(
    private toatsr: ToastrService,
    // private dialogMsg: MessageDialogService
  ) {}

  @BlockUI() msg: NgBlockUI;
  /* @BlockUI('Pledge') msgPledge: NgBlockUI; */
  @BlockUI() msgPledge: NgBlockUI;
  @BlockUI() msgDonation: NgBlockUI;
  PledgeIsAcitve = false;
  DonationIsActive = false;

  ListUrl: string[] = [];

  /* showDialog(
    title: string,
    msg: string[],
    type: EDialogType,
    size: { width: string; height: string } = null
  ): Observable<EDialogResult> {
    return this.dialogMsg.openDialog(title, msg, type, size);
  } */

  /* async showDialogPromise(
    title: string,
    msg: string[],
    type: EDialogType,
    size: { width: string; height: string } = null
  ): Promise<EDialogResult> {
    return await this.dialogMsg.openDialog(title, msg, type, size).toPromise();
  } */

  error(msg: string, title?: string) {
    this.toatsr.toastrConfig.timeOut = 20000;
    this.toatsr.error(msg, title);
    console.log(msg);
  }

  warning(msg: string, title?: string) {
    if (!title || title === '') {
      title = 'Attenzione';
    }
    this.toatsr.toastrConfig.timeOut = 20000;
    this.toatsr.warning(msg, title);
    console.log(msg);
  }

  info(msg: string, title?: string) {
    if (!title || title === '') {
      title = 'Info';
    }
    this.toatsr.toastrConfig.timeOut = 20000;
    this.toatsr.info(msg, title);
    console.log(msg);
  }
  success(msg: string, title?: string) {
    console.log(msg);
    this.toatsr.toastrConfig.timeOut = 5000;
    this.toatsr.success(msg, title);
  }

  startIntercept(url: string, msg?: string) {
    this.ListUrl.push(url);
    // console.log(`START ${url} |-------------------------------->  ${this.ListUrl.length}`);

    if (this.ListUrl.length >= 2 && this.ListUrl.length < 5) {
      msg = 'Caricamento in corso...';
    }
    if (this.ListUrl.length >= 5 && this.ListUrl.length < 6) {
      msg = 'Attendere...';
    }
    if (this.ListUrl.length >= 6) {
      msg = 'Connessione al server in corso...';
    }
    this.msg.start(msg); //  + ' ' + this.ListUrl.length);
  }

  stopIntercept(url: string) {
    this.ListUrl = this.ListUrl.filter((u) => u !== url);
    // console.log(`STOP ${url} |-------------------------------->  ${this.ListUrl.length}`);
    /* if (this.ListUrl.length === 0) { con il reset non tiene conto del delay del forroot
      this.msg.reset(); 
     } */
    this.msg.stop(); // ora lo stop gestisce il num di prec. start
  }

  start(msg?: string) {
    /* this.msg.start(msg); */
  }

  stop() {
    /* if (!this.PledgeIsAcitve && !this.DonationIsActive) {
      this.msg.stop();
    } */
  }
}
import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../sortable.directive';
import { AbstractService } from './abstractservice';

import { Notification } from '../model/notification';
import { SERVER_BASE_URL } from '../constants';
import { User } from '../model/user';



@Injectable({
  providedIn: 'root'
})
export class NotificationService extends AbstractService<Notification, number> {


  constructor(http: HttpClient, private pipe: DecimalPipe) { 
    super(http);
    this.type = "notification";
  }



  countNotification(){
    return this.http.get<any>(SERVER_BASE_URL+'notification/false/count');
   }

   changeStatus(id: number, idUser: number){
    return this.http.post<any>(SERVER_BASE_URL+'notification/changestatus/' + id + "/" + idUser, {headers: this.headerOpt});
   }

   changeStatusinfalse(id: number, idUser: number){
    return this.http.post<any>(SERVER_BASE_URL+'notification/changestatusinfalse/' + id + "/" + idUser, {headers: this.headerOpt});
   }



}

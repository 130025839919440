import { Contest } from './contest';
import { User } from './user';

export class JurorId{
    contestId: number;
    userId: number;
}

export interface Role {
    id: number;
    name: string;
    weight: number;
}

export interface Juror {
    createdDate: number;
    createdBy?: any;
    modifiedDate: number;
    modifiedBy?: any;
    version: number;
    id: number;
    subscription: Date;
    user: User;
    contest: Contest;
    media?: any;
    role: Role;
}

import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { LoginService } from './service/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const checkLogin = LoginService.checkLogin;
    console.log('checkLogin', checkLogin);
    // if (checkLogin == true) {
    //     console.log("you can pass");
    //     return true;
    // } else {
    //     console.log("you cant pass");
    //     this.router.navigate(['/login']);
    //     return false
    // }

    if (localStorage.getItem('currentUser')) {
      console.log('you can pass');
      // logged in so return true
      return true;
    } else {
      console.log('you cant pass', state.url);
      if(state.url !="/"){
        LoginService.urlBeforeLogin = state.url;
      }
      this.router.navigate(['/login']);
      return false;
    }
  }
}

import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '../service/notification.service';
import { NgbdSortableHeader, SortEvent } from '../sortable.directive';

import { Notification } from '../model/notification';
import { CommentService } from '../service/comment.service';
import { azione, dataApertura, dataChiusura, elemento, labelClose, labelOpen, livello, messaggio, page, pageSize1, pageSize2, pageSize3, pageSize4, segnala, size, status } from '../constants';
import { DefaultLayoutComponent } from '../containers';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css', '../../style.css'],
  providers: [DecimalPipe,NotificationService]
})
export class NotificationComponent implements OnInit {

  notifications: Notification[]; /*array contenente tutte le notifiche*/
  showedNotifications: Notification[];
  totalNotifications: number; /*num totale di notifiche*/
  notificationType: String ="all";

  page = page; /*valori paginazione*/
  size = size;
  pageSize1 = pageSize1;
  pageSize2 = pageSize2;
  pageSize3 = pageSize3;
  pageSize4 = pageSize4;

  /*const etichette apertura-chiusura segnalazione*/
  labelOpen = labelOpen; 
  labelClose = labelClose;
  
  /*const header tabella notifiche*/
  livello = livello;
  elemento = elemento;
  messaggio = messaggio;
  status = status;
  dataApertura = dataApertura;
  dataChiusura = dataChiusura;
  azione = azione;
  segnala = segnala;



  constructor(private notificationService: NotificationService, private commentService: CommentService) {
  }

  ngOnInit(): void {
    this.findUncheckedNotification();
    this.findAllNotification(this.notificationType,page,size);
  }

  findAllNotification(value,page,size) {
    console.log(value);
    switch (value) {
      case "all":
    this.notificationService.findAllPaginate(page,size).subscribe(notification => {
      this.notifications = notification.content;
      this.showedNotifications = this.notifications;
      console.log("tot not", this.notifications);
      this.totalNotifications = notification.totalElements;
    });
    break;

    case "open":
      this.showedNotifications=[];
          for(var i = 0; i < this.notifications.length; i++) {
            console.log(this.notifications[i].status);
            if((this.notifications[i].status == false) ){
              this.showedNotifications.push(this.notifications[i]);
            }
          }
          this.totalNotifications = this.showedNotifications.length;
    break;

    case "closed":
      this.showedNotifications=[];
      for(var i = 0; i < this.notifications.length; i++) {
        console.log(this.notifications[i].status);
        if((this.notifications[i].status == true) ){
          this.showedNotifications.push(this.notifications[i]);
        }
      }
      this.totalNotifications = this.showedNotifications.length;
    break;
  }
  }

  public findUncheckedNotification(){
    this.notificationService.countNotification().subscribe(uncheckednotification => {
      DefaultLayoutComponent.uncheckednotification = uncheckednotification;

      console.log("numero", uncheckednotification);
    });
    
  }



}

import { Page } from './page';

export class User {
    /* userId: number;
    userName: String;
    password: String;
    enabled: boolean;
    firstName: String;
    lastName: String;
    language: String;
    email: String;
    city: String;
    pin: String;
    phone: String;
    imageUri: String;
    bio: String;
    dateOfBirth: Date;
    facebookId: String;
    blocked: boolean;

   

    accessToken: String; */
    createdDate:           number;
    createdBy:             null;
    modifiedDate:          number;
    modifiedBy:            null;
    version:               number;
    userId:                number;
    userName:              null;
    enabled:               boolean;
    firstName:             string;
    lastName:              string;
    language:              string;
    email:                 string;
    city:                  string;
    phone:                 null;
    imageUri:              null;
    bio:                   string;
    dateOfBirth:           Date;
    facebookId:            null;
    theUserProfile:        UserProfile[];
    theUserFollower:       any[];
    theUserFollowing:      any[];
    blocked:               boolean;
    theNotificationRaised: any[];
    fullName:              string; 
    accessToken: String;
}

export interface UserProfile {
    profileId:   number;
    profileName: string;
}
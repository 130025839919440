import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { changePasswordAdmin } from '../model/changePasswordAdmin';
import { LoginService } from '../service/login.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {

  
  user = new changePasswordAdmin();
  alertSuccess: boolean = false; //è true se la passw è stata modificata

  salt : any;
  seme: any;
  myForm: any;

  notcorrectPassword: boolean = false;
  notcorrectOldPassword: boolean = false;

  constructor(private loginService: LoginService, private userService: UserService) { }

  ngOnInit(): void {
  }

  resetPassword(oldPassword: String, newPassword: String, newPassword2: String, form: NgForm){
   
    console.log("prima",oldPassword, newPassword, newPassword2);
    if(newPassword == newPassword2){
      this.notcorrectPassword = false;
    this.loginService.getSalt().subscribe( /*carico il salt*/
      getsalt => {
      this.salt = getsalt.toString();
      this.seme = this.salt.split("@");
      this.user.newPassword = btoa(this.seme[0]+newPassword+this.seme[1]); /*codifico passw*/
      this.user.oldPassword = btoa(this.seme[0]+oldPassword+this.seme[1]);
     
      //creo il form data
      const formData = new FormData();
      const c = JSON.stringify(this.user);
      const blob = new Blob([c], {
        type: 'application/json'
        });
      formData.append('user',blob);

      this.userService.changePassword(this.user, formData).pipe(
        catchError(err => {
          this.notcorrectOldPassword = true;
          setTimeout(()=>{  this.notcorrectOldPassword = false; }, 3000);
          return throwError(err);
        })
      ).subscribe(()=>{
        this.alertSuccess = true;
        setTimeout(()=>{  this.alertSuccess = false; }, 3000);
        form.resetForm(); /*resetto il form*/
        console.log("passwordmodificata");
      });
    });
  } else {
    this.notcorrectPassword = true;
    setTimeout(()=>{  this.notcorrectPassword = false; }, 3000);
    console.log("inserire 2 passw uguali");
  }
  }

}

import { Media } from './media';
import { Notification } from './notification';
import {Page} from './page';
import { User } from './user';


export class Comment {
  commentId: number;
  message: String;
  deletedOn: Date;
  theUser: User;
  theMedia: number;
  notifications: Notification[];
  page: Page;

}

import { Component, forwardRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { User } from '../model/user';
import { UserService } from '../service/user.service';
import { MediaService } from '../service/media.service';
import { Media } from '../model/media';
import { CommentService } from '../service/comment.service';
import { NotificationService } from '../service/notification.service';
import { AbstractService } from '../service/abstractservice';
import { MediaNotificationsService } from '../service/media-notification.service';
import { admin, azione, dataApertura, dataChiusura, elemento, labelClose, labelOpen, livello, messaggio, page, pageSize1, pageSize2, pageSize3, pageSize4, segnala, size, status, user } from '../constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { DefaultLayoutComponent } from '../containers';


var COMMENTS: Comment[] = [];


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css','../../style.css'],
})
export class UserDetailsComponent implements OnInit {
  
  user = new User();
  userId = +this.route.snapshot.paramMap.get('userId');

  notifications: Notification[];
  totalNotifications: number;
  page = page;
  size = size;

  notificationsReceived: Notification[];
  totalNotificationsReceived: number;
  showNotifications: boolean = true;
  showNotificationsReceived: boolean = true;
  pageReceived = page;
  sizeReceived = size;

  medias: Media[];
  totalMedias: number;
  pageMedias = page;
  sizeMedias = size;
  
  
  pageSize1 = pageSize1;
  pageSize2 = pageSize2;
  pageSize3 = pageSize3;
  pageSize4 = pageSize4;

  comments: Comment[];
  totalComments: number;
  pageComments = page;
  sizeComments = size;

  ActiveisShown: boolean = false;
  BlockedisShown: boolean = false;

  MediaisShown: boolean = false;
  CommentisShown: boolean = false;
  NotificationisShown: boolean = false;

  admin: boolean = false; //specifica se l'utente è admin

  //for admin
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public static admin: User;

  /*etichetta aperta-chiusa notifica*/
  labelOpen = labelOpen;
  labelClose = labelClose;

    /*const header tabella notifiche*/
    livello = livello;
    elemento = elemento;
    messaggio = messaggio;
    status = status;
    dataApertura = dataApertura;
    dataChiusura = dataChiusura;
    azione = azione;
    segnala = segnala;

  /*label ruolo: user, admin*/
  labelUser =  user;
  labelAdmin = admin;


  constructor(private location: Location,
    private route: ActivatedRoute,
    private userService: UserService,
    private mediaService: MediaService,
    private commentService: CommentService,
    private notificationService: NotificationService,
    private mediaNotificationService: MediaNotificationsService,
  ) { }

  ngOnInit(): void {
    this.currentUserValue;
    this.getUser(this.userId);
    this.getMedia(this.pageMedias,this.sizeMedias);
    this.getComment(this.pageComments, this.sizeComments);
    this.getNotification(page,size);
    this.getNotificationReceived(this.pageReceived,this.sizeReceived);
    // console.log(this.user, this.userId, this.collectionSize);
    
  }

  //reload page
  reload() {
    window.location.reload();
  }

  getUser(userId) {
    this.userService.findById(userId).subscribe(user => {
      this.user = user
      console.log("user", this.user);
      if (user.blocked == false) {
        this.ActiveisShown = true;
      } else {
        this.BlockedisShown = true;
      }
      for(let i=0; i<user.theUserProfile.length; i++){
        if(user.theUserProfile[i].profileName == 'ROLE_ADMIN'){
          this.admin = true;
        }
      }
    });
  }

  getMedia(page,size) {
    this.mediaService.filterMediaByUserId(this.userId,page,size).subscribe(media => {
      this.medias = media.content;
      this.totalMedias = media.totalElements;
      console.log("media utente",this.medias);
    });
  }

  getComment(page,size) {
    this.commentService.filterCommentByUserId(this.userId, page,size).subscribe(comment => {
      this.comments = comment.content;
      this.totalComments = comment.totalElements;
      console.log("comments", this.comments);
    });
  }

  getNotification(page,size) {
    console.log("this.page",this.page);
    this.mediaNotificationService.filterNotificationByUserId(this.userId,page,size).subscribe(notification => {
      this.notifications = notification.content;
      this.totalNotifications = notification.totalElements;
      if(this.notifications.length == 0){ this.showNotifications = false} else { this.showNotifications = true};
      console.log("this.totalNotifications",this.totalNotifications);

    });
  }

  getNotificationReceived(pageReceived,sizeReceived) {
    this.mediaNotificationService.filterNotificationReceivedByUserId(this.userId,pageReceived,sizeReceived).subscribe(notification => {
      this.notificationsReceived= notification.content;
      this.totalNotificationsReceived = notification.totalElements;
      if(this.notificationsReceived.length == 0){ this.showNotificationsReceived = false} else { this.showNotificationsReceived = true};
      console.log("notifiche ricevute",this.notificationsReceived);

    });
  }

  changeStatus(idNotification:number){
    this.notificationService.changeStatus(idNotification, UserDetailsComponent.admin.userId).subscribe(() => {
      DefaultLayoutComponent.uncheckednotification = DefaultLayoutComponent.uncheckednotification -1 ;
      this.ngOnInit();
  });
  }

  changeStatusinfalse(idNotification:number){
    this.notificationService.changeStatusinfalse(idNotification, UserDetailsComponent.admin.userId).subscribe(() => {
      DefaultLayoutComponent.uncheckednotification = DefaultLayoutComponent.uncheckednotification -1 ;
      this.ngOnInit();
  });
  }


  blockUser() {
    console.log(this.userId);
    this.userService.blockUser(this.userId).subscribe(() => {
      this.ngOnInit();
    });
  }

unblockUser(){
  this.userService.unblockUser(this.userId).subscribe(() => {
    this.ngOnInit();
  });
}

deleteUser(){
  this.userService.deleteUser(this.userId).subscribe(() => {
    this.ngOnInit();
  });
}

changeRole(){
  this.userService.changeRole(this.userId).subscribe(() => {
    this.ngOnInit();
  });
}


    // Torna indietro
    goBack() {
      this.location.back();
    }

    
  public get currentUserValue(): User {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    console.log("this.currentUser", this.currentUserSubject.value.email);
    this.searchUserByEmail(this.currentUserSubject.value.email);
    return this.currentUserSubject.value;
  }

  public searchUserByEmail(email: String){
    this.userService.searchByEmail(email).subscribe(userfound => {
      UserDetailsComponent.admin = userfound;
      console.log("UTENTE",   UserDetailsComponent.admin);
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { Login } from '../model/login';
import { User } from '../model/user';
import { LoginService } from '../service/login.service';
import { MediaDetailService } from '../service/media-detail.service';
import { UserService } from '../service/user.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements CanActivate {

  login = new Login();
  newlogin = new Login();

  salt : any;
  seme1: any;
  psw: any;

  public static loggedInUser: any;

  correctLogin: boolean = false;

  constructor(private loginService: LoginService, private router: Router, private userService: UserService, public myapp: AppComponent) { }

  ngOnInit(): void {
    console.log("log",LoginService.checkLogin);
  }

  canActivate(): Observable<boolean> | boolean {

    if (this.correctLogin == true) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

  Accedi(login: Login) {
    this.loginService.getSalt().subscribe(
      getsalt => {
      this.salt = getsalt.toString();
      this.seme1 = this.salt.split("@");
      this.psw = this.seme1[0]+login.password+this.seme1[1];
      
      this.newlogin.email = login.email;
      this.newlogin.password =  btoa(this.psw);
   
     

    
    this.loginService.Login(this.newlogin).pipe(
      catchError(err => {
        console.log("errore");
        this.correctLogin = true;
        return throwError(err);
      })
    ).subscribe((user: any) => {
      this.correctLogin = false;
      LoginService.checkLogin = true;
        localStorage.setItem('currentUser', JSON.stringify(user));
        LoginComponent.loggedInUser = user;
        console.log(LoginComponent.loggedInUser.email);
        this.loginService.currentUserValue;
        this.myapp.foundSessionExpire();
        if(LoginService.urlBeforeLogin!= ""){
          this.router.navigateByUrl(LoginService.urlBeforeLogin);  
          setTimeout(() => {
            LoginService.urlBeforeLogin = "";
          }, 1000);
        } else {
          this.router.navigateByUrl('/dashboard');
        }
        
        return user;
      }); 
    });
    
    
     
    }


}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { HttpClientModule } from '@angular/common/http';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
// import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import {  HTTP_INTERCEPTORS } from '@angular/common/http';



const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediaComponent } from './media/media.component';
import { ContestComponent } from './contest/contest.component';
import { CommentComponent } from './comment/comment.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { JwtInterceptor } from './jwt.interceptor';
import { UserDetailsComponent } from './user-details/user-details.component';
import { MediaDetailsComponent } from './media-details/media-details.component';
import { NgbdSortableHeader } from './sortable.directive';
import { RouterModule } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { NotificationComponent } from './notification/notification.component';
import { UserComponent } from './user/user.component';
import { ContestDetailsComponent } from './contest-details/contest-details.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ConfigurationDetailsComponent } from './configuration-details/configuration-details.component';
import { AccessComponent } from './access/access.component';
import { PartecipantComponent } from './partecipant/partecipant.component';
import { MediaContestClassificationComponent } from './media-contest-classification/media-contest-classification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { UIService } from './service/ui.service';
import { ErrorInterceptor } from './error.interceptor';
import { ToggleButtonComponent } from './views/toggle-button/toggle-button.component';
import { DialogComponent } from './views/dialog/dialog.component';






//

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    ToastrModule.forRoot({
      // timeOut: 20000, impostato alla chiamata
      enableHtml: true,
      positionClass: 'toast-top-right',
      // positionClass: 'toast-bottom-full-width',
      closeButton: true,
      progressBar: true,
      extendedTimeOut: 20000,
    }),
    BlockUIModule.forRoot({
      delayStart: 100,
      delayStop: 400,
    }),
  ],
  declarations: [
    AppComponent,
    //...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    DefaultLayoutComponent,
    UserComponent,
    MediaComponent,
    ContestComponent,
    CommentComponent,
    UserDetailsComponent,
    MediaDetailsComponent,
    NgbdSortableHeader,
    NotificationComponent,
    CategoryComponent,
    ContestDetailsComponent,
    ConfigurationComponent,
    ConfigurationDetailsComponent,
    AccessComponent,
    PartecipantComponent,
    MediaContestClassificationComponent,
    ForgotPasswordComponent,
    RecoveryPasswordComponent,
    AdminPageComponent,
    ToggleButtonComponent,
    DialogComponent,
  ],
  providers: [
    UIService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: DecimalPipe}
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { Configuration } from '../model/configuration';
import { ConfigurationService } from '../service/configuration.service';
import { UIService } from '../service/ui.service';

@Component({
  selector: 'app-configuration-details',
  templateUrl: './configuration-details.component.html',
  styleUrls: ['./configuration-details.component.css'],
})
export class ConfigurationDetailsComponent implements OnInit {
  configuration = new Configuration();
  configurationLabel = '';
  configurationId = 0;

  value: String;

  deleteIsShown: boolean = true;
  modifyIsShown: boolean = true;

  isDisabled = true;

  inModify: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private msg: UIService,
    private location: Location,
    private configurationService: ConfigurationService,
    public myapp: AppComponent
  ) {}

  ngOnInit(): void {
    console.log(this.configuration);
    this.configurationLabel = this.route.snapshot.paramMap.get('label');
    this.configurationId = +this.route.snapshot.paramMap.get('id');

    if (this.configurationLabel != '') {
      this.getConfiguration(this.configurationLabel);
    } else {
      //Nuova configuration
      this.inModify = true;
      this.deleteIsShown = false;
      this.modifyIsShown = false;
      this.isDisabled = false;
    }
  }

  getConfiguration(label: string) {
    this.configurationService
      .searchByLabel(label)
      .subscribe((configuration) => {
        this.configuration = configuration;
        this.value = configuration.value;
        console.log(this.configuration);
      });
  }

  //reload page
  reload() {
    window.location.reload();
  }

  // Torna indietro
  goBack() {
    this.location.back();
  }

  setConfiguration(configuration: Configuration) {
    if (this.configurationLabel == null) {
      this.configurationService.save(configuration).subscribe(
        () => {
          this.msg.success('Valore inserito con successo.');
          console.log(configuration);
          this.goBack();
        },
        (error) => this.msg.error(error)
      );
    } else {
      this.isDisabled = true;
      this.configurationService
        .update(this.configurationId, configuration)
        .subscribe(
          () => {
            this.msg.success('Valore modificato con successo.');
            this.myapp.foundSessionExpire(); //ricarico timeout sessione
            this.goBack();
          },
          (error) => {
            this.msg.error('Errore del server');
            console.log(error);
          }
        );
      console.log('configuration after save', this.configuration);
    }
  }

  deleteConfiguration() {
    this.configurationService.delete(this.configurationId).subscribe(
      () => {
        this.msg.success('Valore eliminato con successo.');
        this.goBack();
      },
      (error) => {
        this.msg.error("Errore del Server");
        console.log(error);
      }
    );
  }

  edit() {
    this.isDisabled = false;
    this.inModify = true;
  }
  cancel() {
    this.isDisabled = true;
    this.inModify = false;
    this.configuration.value = this.value;
  }
}

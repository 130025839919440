import {DecimalPipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { SERVER_BASE_URL } from '../constants';
import {Media} from '../model/media';
import {AbstractService} from './abstractservice';


@Injectable({providedIn: 'root'})
export class MediaService extends AbstractService<Media, number> {


  constructor(http: HttpClient) {
    super(http);
    this.type = 'media';
  }


  findSinging(published: boolean, page:number, size:number): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL+'media/filterby/category/1?page='+page+'&size='+size+'&published='+published+'');
  }

  findRecitation(published: boolean, page:number, size:number): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL+'media/filterby/category/2?page='+page+'&size='+size+'&published='+published+'');
  }

  findDirecting(published: boolean, page:number, size:number): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL+'media/filterby/category/3?page='+page+'&size='+size+'&published='+published+'');
  }

  
  filterMediaByUserId(id: number,page,size): Observable<any>{
    return this.http.get<any>(SERVER_BASE_URL+'media/filterby/user/' + id + '/?page='+page+'&size='+size+'&published='+false+'');
  }

  searchByName(name: string, page, size): Observable<any>{
    return this.http.get<any>(SERVER_BASE_URL+'media/title/' + name + '/?page='+page+'&size='+size+'&published='+false+'' );
  }

  getmediaContestClassification(id: number, page: number, size:number){
    return this.http.get<any>(SERVER_BASE_URL+'media/top/' + id + '/?page='+page+'&size='+size+'&published='+false+'');
  }





}

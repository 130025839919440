import { Location } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import {
  page,
  pageSize1,
  pageSize2,
  pageSize3,
  pageSize4,
  size,
} from '../constants';
import { Category } from '../model/category';
import { Contest } from '../model/contest';
import { MediaContestClassification } from '../model/mediaContestClassification';
import { Juror, JurorId } from '../model/juror';
import { CategoryService } from '../service/category.service';
import { ContestService } from '../service/contest.service';
import { MediaService } from '../service/media.service';
import { JurorService } from '../service/juror.service';
import { UserDetailsComponent } from '../user-details/user-details.component';
import { UserComponent } from '../user/user.component';
import { User } from '../model/user';
import { UIService } from '../service/ui.service';

export var id: number;

// export function  addUserToPartecipant(userId: number){
//   console.log("valori", userId, this.contestId)
// this.partecipantService.save(partecipant).subscribe(() => this.reload());
// }

@Component({
  selector: 'app-contest-details',
  templateUrl: './contest-details.component.html',
  styleUrls: ['./contest-details.component.css', '../../style.css'],
})
export class ContestDetailsComponent implements OnInit {
  contest = new Contest();

  contestId: number; // = +this.route.snapshot.paramMap.get('contestId');
  parentId = 0;

  nuovoRoundLink: string;

  theCategory: Category[];
  jurors: Juror[];

  sub: Subscription;
  //isDisabled = true;

  inModify: boolean = false;
  myForm: any;
  rateControl: any;
  tmpImage: any;

  sizeMediaContestClassification = size;
  pageMediaContestClassification = page;
  pageSize1 = pageSize1;
  pageSize2 = pageSize2;
  pageSize3 = pageSize3;
  pageSize4 = pageSize4;

  mediaContestClassification: MediaContestClassification[];
  totalMediaContestClassification: number;
  modalJurorVisible = false;
  contestDetail: boolean;
  nuovoRound: boolean;
  roundDetails: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
    private contestService: ContestService,
    private partecipantService: JurorService,
    private mediaService: MediaService,
    private location: Location,
    private formBuilder: FormBuilder,
    private msg: UIService
  ) {
    this.route.params.subscribe((p) => {
      this.contestDetail = this.router.url.startsWith('/contestdetails');
      this.nuovoRound = this.router.url.startsWith('/nuovoround');
      this.roundDetails = this.router.url.startsWith('/rounddetails');

      if (this.contestDetail) {
        ///////////DETTAGLIO CONTEST
        this.contestId = +p.contestId;
        if (this.contestId > 0) {
          if (this.contest != null) {
            this.getContest(this.contestId);
            this.getJurorOfContest();
            this.getmediaContestClassification(
              this.pageMediaContestClassification,
              this.sizeMediaContestClassification
            );
          }
        } else {
          //////////////////NUOVO CONTEST
          this.getCategory();
          this.inModify = true;
        }
      }
      if (this.nuovoRound) {
        ///////// NUOVO ROUND
        this.parentId = +p.contestId;
        this.contestId = -1;
        this.contest.round = +p.roundNumber;
        
        this.inModify = true;
      }
      if (this.roundDetails) {
        /////////// DETTAGLIO ROUND
        this.parentId = +p.contestId;
        this.contestId = +p.roundId;

        if (this.contest != null) {
          this.getContest(this.contestId);
          this.getJurorOfContest();
          this.getmediaContestClassification(
            this.pageMediaContestClassification,
            this.sizeMediaContestClassification
          );
        }
      }
    });
  }

  ngOnInit(): void {
    this.myForm = this.formBuilder.group({
      description: ['', Validators.compose([Validators.minLength(145)])],
      cover: [''],
    });

    /* if (this.contestId > 0 && (!this.roundNumber || this.roundNumber == 0)) {
      if (this.contest != null) {
        this.getContest(this.contestId);
        this.getJurorOfContest();
        this.getmediaContestClassification(
          this.pageMediaContestClassification,
          this.sizeMediaContestClassification
        );
      }
    } else {
      //Nuovo contest
      this.getCategory();
      this.inModify = true;
      if (this.roundNumber > 0) {
        this.contest.round = this.roundNumber;
      }
    } */
  }

  reload() {
    window.location.reload();
  }

  getContest(id: number) {
    this.contestService.findOne(id).subscribe((contest) => {
      this.contest = contest;
      this.nuovoRoundLink = `/nuovoround/${this.contestId}/${
        this.contest?.rounds?.length + 1
      }`;
    });
  }

  closeModalJurors() {
    this.modalJurorVisible = false;
  }
  openModalJurors() {
    this.modalJurorVisible = true;
  }

  getCategory() {
    this.categoryService.findAll().subscribe((category) => {
      this.theCategory = category;
      console.log(this.theCategory);
    });
  }

  modification() {
    //this.isDisabled = false;
    this.inModify = true;
  }

  // Torna indietro
  goBack() {
    this.location.back();
  }

  setContest(contest: Contest) {
    //preparo il formdata
    const formData = new FormData();
    if (
      this.myForm.get('cover').value != null ||
      this.myForm.get('cover').value != ''
    ) {
      formData.append('file', this.myForm.get('cover').value);
    }

    //contest.medias = null;

    const c = JSON.stringify(contest);
    const blob = new Blob([c], {
      type: 'application/json',
    });
    formData.append('contest', blob);

    //Contest esistente
    if (this.contestId > 0) {
      this.contestService
        .updateContest(this.contestId, formData)
        .subscribe((res) => {
          this.msg.success("Contest inserito correttamente.");
          this.inModify = false;
        }, error=>{
          this.msg.error("Errore nell'inserimento del contest.")
        });
    } else {
      //nuovo Contest
      this.contestService.saveContestWithMedia(formData).subscribe((res) => {
        this.msg.success("Contest inserito correttamente.");
        this.inModify = false;
      }, error=>{
        this.msg.error("Errore nell'inserimento del contest.")
      });
    }
  }

  getJurorOfContest() {
    let id = this.parentId == 0 ? this.contestId : this.parentId;
    this.partecipantService.findJurors(id).subscribe((jurors) => {
      this.jurors = jurors;
      console.log('giudici', this.jurors);
    });
  }

  deleteJurorOfContest(id: number) {
    this.partecipantService.deleteParticipant(id).subscribe(() => {
      this.getJurorOfContest();
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      var mimeType = event.target.files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        console.log('Only images are supported.');
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.tmpImage = reader.result;
      };

      const file = event.target.files[0];
      console.log(event);
      this.myForm.get('cover').setValue(file);
      this.tmpImage = event.target.files[0];
      console.log(this.tmpImage);
    }
  }

  highlightedContest(checked: boolean) {
    this.contest.highlighted = checked;
    this.contestService.highlightedToggleContest(this.contestId).subscribe(
      () => {},
      (error) => {
        this.contest.highlighted = !checked;
        this.msg.error('Operazione non riuscita.' + error);
      }
    );
  }

  publishToggleContest(checked: boolean) {
    this.contest.published = checked;
    this.contestService.publishedToggleContest(this.contestId).subscribe(
      () => {},
      (error) => {
        this.contest.published = !checked;
        this.msg.error('Operazione non riuscita.' + error);
      }
    );
  }

  deleteToggleContest(checked: boolean) {
    if (checked) {
      this.contest.deleted = false;
      this.contestService.restoreContest(this.contestId).subscribe(
        () => {},
        (error) => {
          this.contest.deleted = true;
          this.msg.error('Operazione non riuscita.' + error);
        }
      );
    } else {
      this.contest.deleted = true;
      this.contestService.deleteContest(this.contestId).subscribe(
        () => {},
        (error) => {
          this.contest.deleted = false;
          this.msg.error('Operazione non riuscita.' + error);
        }
      );
    }
  }

  getmediaContestClassification(page, size) {
    this.mediaService
      .getmediaContestClassification(this.contestId, page, size)
      .subscribe((classification) => {
        this.mediaContestClassification = classification.content;
        this.totalMediaContestClassification = classification.totalElements;
        console.log(
          'this.mediaContestClassification ',
          this.mediaContestClassification
        );
      });
  }

  OpenRound(roundId: number) {
    this.router
      .navigate(['/rounddetails', this.contestId, roundId])
      .then(() => {
        window.location.reload();
      });
  }
}

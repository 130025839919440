import { Category } from "./category";
import { Media } from "./media";
import { Page } from "./page";

export class Contest{
    /* contestId: number;
    contestName: String;
    startDateTime: Date;
    endDateTime: Date;
    contestDescription: String;
    mediaUri: boolean;
    prizeTitle: String;
    prizeSubtitle: String;
    prizeDescription: String;

    deleted: boolean;
    highlighted: boolean;

    medias: Media[];
    theCategory: Category; */

    contestId:          number;
    contestName:        string;
    startDateTime:      Date;
    endDateTime:        Date;
    contestDescription: string;
    prizeTitle:         string;
    prizeSubtitle:      string;
    prizeDescription:   string;
    mediaUri:           string;
    theCategory:        Category;
    canParticipate:     boolean;
    media:              null;
    participantId:      null;
    judge:              boolean;
    deleted:            boolean;
    highlighted:        boolean;
    published:          boolean;
    round:              number;
    threshold:          null;
    rounds:             Contest[];
    participantWinner:  ParticipantWinner;
    participating:      boolean;

    //add medias and category

    constructor(){
        this.contestName = this.contestName;
        this.startDateTime = this.startDateTime;
        this.endDateTime = this.endDateTime;
        this.prizeTitle = this.prizeTitle;
        this.prizeSubtitle = this.prizeSubtitle;
        this.prizeDescription = this.prizeDescription;
        this.contestDescription = this.contestDescription;
        this.mediaUri = this.mediaUri;
        this.deleted = this.deleted;
        this.theCategory = new Category();
      }
      
}

export interface ParticipantWinner {
  id:           number;
  media:        Media;
  contest:      Contest;
  subscription: Date;
  votes:        number;
  position:     number;
}
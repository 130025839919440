import {DecimalPipe} from '@angular/common';
import {Component, Directive, EventEmitter, Input, OnInit, Output, PipeTransform, QueryList, ViewChildren} from '@angular/core';
import {Observable} from 'rxjs';
import { page, pageSize1, pageSize2, pageSize3, pageSize4, size } from '../constants';
import {Media} from '../model/media';
import {MediaService} from '../service/media.service';
import { UIService } from '../service/ui.service';
import {NgbdSortableHeader, SortEvent} from '../sortable.directive';


@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css', '../../style.css'],
  providers: [MediaService, DecimalPipe]
})
export class MediaComponent implements OnInit {

  MEDIAS: Media[]; /*media totali nella ricerca*/

  notification: number;
  search: string; /*stringa cercata*/

  mediasinging: Media[];
  mediarecitation: Media[];
  mediadirecting: Media[];

  totalMediasinging: number;
  totalMediarecitation: number;
  totalMediadirecting: number;
  totalFoundMedia: number;

  medias: Media[];

  searchMedia: boolean = false;

  defaultPage = page;
  defaultSize = size;
  pageCanto = page;
  sizeCanto = size;
  pageRecitazione = page;
  sizeRecitazione = size;
  pageRegia = page;
  sizeRegia = size;
  
  pageSearch = page;
  sizeSearch = size;

  pageSize1 = pageSize1;
  pageSize2 = pageSize2;
  pageSize3 = pageSize3;
  pageSize4 = pageSize4;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private mediaService: MediaService, private msg:UIService) {
  }


  ngOnInit(): void {
    this.searchMedia = false;
    this.findMediaSinging(this.pageCanto, this.sizeCanto);
    this.findMediaRecitation(this.pageRecitazione, this.sizeRecitazione);
    this.findMediaDirecting(this.pageRegia, this.sizeRegia);
  }


  findMediaSinging(page, size) {
    this.mediaService.findSinging(false, page, size).subscribe(mediasinging => {
      this.mediasinging = mediasinging.content;
      this.totalMediasinging = mediasinging.totalElements;
      console.log(this.mediasinging, this.totalMediasinging);
    });

  }

  findMediaRecitation(page, size) {
    this.mediaService.findRecitation(false, page, size).subscribe(mediarecitation => {
      this.mediarecitation = mediarecitation.content;
      this.totalMediarecitation = mediarecitation.totalElements;
    });
  }

  findMediaDirecting(page, size) {
    this.mediaService.findDirecting(false, page, size).subscribe(mediadirecting => {
      this.mediadirecting = mediadirecting.content;
      this.totalMediadirecting = mediadirecting.totalElements;
    });
  }


  foundTitle(search: string, page, size){
    console.log(search,page,size);
    this.mediaService.searchByName(search, page, size).subscribe(medias => {
      this.medias = medias.content;
      this.totalFoundMedia = medias.totalElements;
      this.searchMedia = true;
      console.log(this.medias);
    });
   
  }

  getTitleCut(media: Media): String{
    if(media.title.length>30){
      return `${media.title.substring(0,30)}...`;
    }
    return media.title;
  }
  getTitleTooltip(media: Media): String{
    if(media.title.length>30){
      return media.title;
    }
    return '';
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login } from '../model/login';
import { AbstractService } from './abstractservice';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { SERVER_BASE_URL } from '../constants';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoginComponent } from '../login/login.component';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends AbstractService<Login, number> {
  private urlLogin = 'http://localhost:8080/auth/login';

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public static checkLogin: boolean = false;
  public static email: String;
  public static urlBeforeLogin = '';

  headerOpt = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: '*/*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  });

  constructor(protected http: HttpClient, private router: Router) {
    super(http);
    // checkLogin = false;
    this.type = 'login';
  }

  public get currentUserValue(): User {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    // LoginService.email = this.currentUserSubject.value.email;
    // console.log("this.currentUser", this.currentUserSubject);
    return this.currentUserSubject.value;
  }

  Login(t: Login) {
    return this.http.post<any>(SERVER_BASE_URL + 'auth/admin/' + this.type, t);
  }

  logout() {
    // remove user from local storage to log user out
    this.router.navigate(['/login']);
    LoginService.checkLogin = false;

    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  getSalt() {
    return this.http.get(SERVER_BASE_URL + 'auth/seme', {
      responseType: 'text',
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVER_BASE_URL } from '../constants';
import { Media } from '../model/media';
import { AbstractService } from './abstractservice';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends AbstractService<Comment, number>  {

  constructor(http: HttpClient){
    super(http);
    this.type = "comment/total/comments";
  }

  filterCommentByUserId(id: number,page,size): Observable<any>{
    return this.http.get<any>(SERVER_BASE_URL+'comment/filter-by/user/' + id + '/?page=' + page + '&size=' + size);
  }

  filterCommentByCommentId(id: number): Observable<any>{
    return this.http.get<any>(SERVER_BASE_URL+'comment/filter-by/comment/' + id);
  }

  filterCommentByMediaId(id: number, page,size): Observable<any>{
    return this.http.get<any>(SERVER_BASE_URL+'comment/filter-by/media/' + id + '/?page=' + page + '&size=' + size );
  }

  deleteComment(id: number): Observable<any> {
    return this.http.delete<any>(SERVER_BASE_URL+'comment/'+ id);
  }
}

import { Component, Directive, EventEmitter, Input, OnInit, Output, PipeTransform, QueryList, ViewChild, ViewChildren } from '@angular/core';
// import { User } from '../model/user';
import { UserService } from '../service/user.service';

import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../sortable.directive';
import { User } from '../model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { JurorId } from '../model/juror';
import { JurorService } from '../service/juror.service';
import { Contest } from '../model/contest';
import { page, size, pageSize1, pageSize2, pageSize3, pageSize4, user, admin } from '../constants';
import { ContestDetailsComponent } from '../contest-details/contest-details.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css','../../style.css'],
  providers: [DecimalPipe,UserService, ContestDetailsComponent]
})
export class UserComponent implements OnInit {

  users: User[];
  totalUsers: number;
  juror = new JurorId();

  @Input() idContestinUser: number;
  //@Output ("getJurorOfContest") getJurorOfContest: EventEmitter <any> = new EventEmitter ();
  @Output() getJurorOfContest = new EventEmitter();
  
  contest = new Contest();

  page = page;
  size = size;
  pageSize1 = pageSize1;
  pageSize2 = pageSize2;
  pageSize3 = pageSize3;
  pageSize4 = pageSize4;

  searchValue: String = "";

  labelUser =  user;
  labelAdmin = admin;
  isSelectJudge=false;


  
  constructor(private router: Router, private userService: UserService, private partecipantService: JurorService) {

  }

  ngOnInit(): void {
    this.findAllUser(page, size);
    this.isSelectJudge = this.router.url!="/utenti";
  }




  findAllUser(page: number, size: number) {
    // console.log("page and size", page, size);
    if(this.searchValue.length==0){
      this.userService.findAllPaginate(page,size).subscribe(users => {
        this.users = users._embedded.user;
        this.totalUsers = users.page.totalElements;
         console.log(this.users);
        // console.log(this.totalUsers);
      });
    }
    
  }
  
addJuror(userId: number, idContestinUser: number){
  console.log( userId, idContestinUser);
  this.juror.contestId = idContestinUser;
  this.juror.userId = userId;
  console.log(this.juror);
  this.partecipantService.save(this.juror).subscribe(() =>{
    this.getJurorOfContest.emit();
  });
}




foundTitle(name: string, page, size){
  this.userService.searchByName(name, page, size).subscribe(users => {
    this.users = users.content;
    this.totalUsers = users.totalElements;
    console.log(this.users);
    
  });
}

onSearchChange(searchValue: string): void {  
  console.log("searchValue",searchValue);
  this.searchValue=searchValue;
  if(searchValue.length==0){
    this.findAllUser(page, size);
  }
}

openDetail(userId:number){
  if(!this.isSelectJudge){
    this.router.navigate(['/userdetails',userId])
  }
}


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVER_BASE_URL } from '../constants';
import { Access } from '../model/access';
import { AbstractService } from './abstractservice';

@Injectable({
  providedIn: 'root'
})
export class AccessService extends AbstractService<Access, number>{

  constructor(http: HttpClient) { 
    super(http);
    this.type = "access";
  }

  getAccess(dateStart: string, dateEnd: string,page,size): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL + 'access/filter-by-date/start/' + dateStart + '/end/' + dateEnd + '/?page=' + page + '&size=' + size);
  }

  getNumberOfAccess(dateStart: string, dateEnd: string): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL + 'access/count-by-date/start/' + dateStart + '/end/' + dateEnd);
  }
}

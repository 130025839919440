import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { LoginComponent } from './login/login.component';
import { User } from './model/user';
import { ConfigurationService } from './service/configuration.service';
import { LoginService } from './service/login.service';
import { MediaDetailService } from './service/media-detail.service';
import { UserService } from './service/user.service';



@Component({
  // tslint:disable-next-line
  selector: 'app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  userActivity;
  userInactive: Subject<any> = new Subject();
  sessionExpire: any; 

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: any;

  email: String = LoginService.email;

  
  public static un: number = 5;
  // public static uncheckednotification;

  sub :  Subscription;
  
  constructor(private router: Router,private configurationService: ConfigurationService, private loginService: LoginService, private userService: UserService) {
    // this.currentUserValue;
    // if (this.currentUser.source._value){
    //   console.log("ESISTE USER CORRENTE");
    //   this.foundSessionExpire();
    // }
   }

  ngOnInit() {
     this.currentUserValue;
     if (this.currentUser.source._value){
      console.log("ESISTE USER CORRENTE");
      this.foundSessionExpire();
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  public get currentUserValue(): User {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    return this.currentUserSubject.value;
  }
  

  setTimeout() {  //se l'utente ha effettuato il login, setta timeout come min*60000
    this.currentUserValue;
    if (this.currentUser.source._value && this.sessionExpire.value > 0 ){
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.sessionExpire.value*60000);
    }
  }

  @HostListener('window:mousemove') refreshUserState() {
    if (this.currentUser.source._value){
    clearTimeout(this.userActivity);
    this.setTimeout();
    }
  }

  foundSessionExpire(){
    this.currentUserValue;
    if (this.currentUser.source._value){
    this.configurationService.searchByLabel("session_expire").subscribe(value => {
      this.sessionExpire = value;
      if(this.sessionExpire.value > 0 ){
        this.setTimeout();
        this.userInactive.subscribe(() => {
          console.log('utente inattivo da minuti', this.sessionExpire.value);
          this.loginService.logout();
        });
      } else {
        console.log("valore non corretto");
      }
    });
  }
  }


  // public searchUserByEmail(email: String){
  //   this.userService.searchByEmail(email).subscribe(user => {
  //     LoginComponent.loggedInUser = user;
  //     console.log("UTENTE",  LoginComponent.loggedInUser);
  //   });
  // }

 

  // get _email() {
  //   this.email = LoginService.email;
  //   // console.log("EMAIL", this.email);
  //   this.searchUserByEmail(this.email);
  //   return LoginService.email;
  // }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ForgotPasswordService } from '../service/forgot-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public static email: String;
  public static pinAdmin: String; //pin ricevuto dall'admin
  pinInserted: String; //pin scritto dall'utente

  notcorrectEmail: boolean = false;
  notcorrectPin: boolean = false;
  alertSuccess: boolean = false; //è true se l'email è stata ritrasmessa correttamente
  step1: boolean = true;
  step2: boolean = false;

  constructor(private router: Router, private service: ForgotPasswordService) { }

  ngOnInit(): void {
  }

  submitEmail(email: String){
    this.service.submitEmail(email).pipe(
      catchError(err => {
        console.log("errore");
        this.notcorrectEmail = true;
        return throwError(err);
      })
    ).subscribe(pinAdmin => {
      ForgotPasswordComponent.pinAdmin = pinAdmin.pin;
      ForgotPasswordComponent.email = email;
      this.notcorrectEmail = false;
      this.step1 = false;
      this.step2 = true;
      console.log("email", email, ForgotPasswordComponent.pinAdmin);
    })
  }

  resendEmail(email: String){
    this.service.resendEmail(email).subscribe(()=>{
      this.alertSuccess = true;
      setTimeout(()=>{  this.alertSuccess = false; }, 2000);
    });
  }

  //controlla se il pin inserito dall'user è corretto
  checkPin(pinInserted: String){
    if (pinInserted == ForgotPasswordComponent.pinAdmin){
      this.notcorrectPin = false;
      this.router.navigateByUrl('recoverypassword');
      console.log("OK");
    } else {
      this.notcorrectPin = true;
      console.log("PIN ERRATO");
    }
  }

}

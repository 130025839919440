import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { RecoveryPassword } from '../model/recoverypsw';
import { ForgotPasswordService } from '../service/forgot-password.service';
import { LoginService } from '../service/login.service';


@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.css']
})
export class RecoveryPasswordComponent implements OnInit {

  passw1: String;
  passw2: String;
  email = ForgotPasswordComponent.email;
  pin = ForgotPasswordComponent.pinAdmin;

  newPassw = new RecoveryPassword();
  notcorrectPassword: boolean = false;

  salt: any;
  seme: any;

  constructor(private service: ForgotPasswordService, private loginService: LoginService, private router: Router) { }

  ngOnInit(): void {
  }

  checkPassword(passw1: String, passw2: String) {
    if (passw1 == passw2) {
      this.notcorrectPassword = false;
      this.loginService.getSalt().subscribe(
        getsalt => {
          this.salt = getsalt.toString();
          this.seme = this.salt.split("@");
          this.newPassw.email = this.email;
          this.newPassw.pin = this.pin;
          console.log("this.pass", this.passw1);
          this.newPassw.password = btoa(this.seme[0] + this.passw1 + this.seme[1]);
          console.log("this.newPassw", this.newPassw)
          this.service.recoveryPassw(this.newPassw).subscribe(() => {
            this.router.navigateByUrl('./login');
            console.log("ok", passw1, this.email, this.pin);
          });
        });
    } else {
      this.notcorrectPassword = true;
      console.log("passw diverse");
    }
  }

}

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MediaDetailService } from '../service/media-detail.service';
import { Media } from '../model/media';
import { Notification } from '../model/notification';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MediaNotificationsService } from '../service/media-notification.service';
import { UserService } from '../service/user.service';
import { CommentService } from '../service/comment.service';
import { Comment } from '../model/comment';
import { User } from '../model/user';
import {
  azione,
  dataApertura,
  dataChiusura,
  elemento,
  labelClose,
  labelOpen,
  livello,
  messaggio,
  page,
  pageSize1,
  pageSize2,
  pageSize3,
  pageSize4,
  segnala,
  size,
  status,
} from '../constants';
import { NotificationService } from '../service/notification.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DefaultLayoutComponent } from '../containers';
import { UIService } from '../service/ui.service';
import { Moderation } from '../model/moderation';

@Component({
  selector: 'app-media-details',
  templateUrl: './media-details.component.html',
  styleUrls: ['./media-details.component.css', '../../style.css'],
})
export class MediaDetailsComponent implements OnInit {
  public media: Media;
  public user: User;
  public sanitizedMediaUri: SafeResourceUrl;

  comments: Comment[];
  showedComments: Comment[];
  totalComments: number;
  commentType: 'all' | 'deleted';

  notifications: Notification[];
  totalNotifications: number;
  showNotifications: boolean = true;
  uncheckednotification: number;

  DeleteisShown: boolean = false;
  DeleteMediaisShown: boolean = true;

  page = page;
  size = size;

  pageNotification = page;
  sizeNotification = size;

  pageSize1 = pageSize1;
  pageSize2 = pageSize2;
  pageSize3 = pageSize3;
  pageSize4 = pageSize4;

  mediaId = parseInt(this.route.snapshot.paramMap.get('mediaId'));
  moderationSelected = -1;

  value: String; //utenti eliminati, tutti o segnalati

  deletedOn: String;

  //for admin
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public static admin: User;

  /*etichetta aperta-chiusa notifica*/
  labelOpen = labelOpen;
  labelClose = labelClose;

  /*const header tabella notifiche*/
  livello = livello;
  elemento = elemento;
  messaggio = messaggio;
  status = status;
  dataApertura = dataApertura;
  dataChiusura = dataChiusura;
  azione = azione;
  segnala = segnala;

  moderations: Moderation;

  @ViewChild(DefaultLayoutComponent)
  defaultLayoutComponent: DefaultLayoutComponent;

  /**
   * Media details component constructor
   *
   * @param location
   * @param route
   * @param mediaService
   * @param notificationService
   * @param userService
   * @param dom
   */
  public constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private mediaService: MediaDetailService,
    private mediaNotificationService: MediaNotificationsService,
    private notificationService: NotificationService,
    private userService: UserService,
    private commentService: CommentService,
    private dom: DomSanitizer,
    private msg: UIService
  ) {}

  ngOnInit(): void {
    this.currentUserValue;
    this.getCommentByMedia(this.mediaId, this.commentType, page, size);
    this.initMedia();
  }

  initMedia() {
    this.mediaService.findOne(this.mediaId).subscribe((media) => {
      this.media = media;
      this.deletedOn = this.media.deletedOn;
      console.log(media.uri);

      this.sanitizedMediaUri = this.dom.bypassSecurityTrustResourceUrl(
        media.uri
      );

      if (this.media.published == false) {
        this.DeleteisShown = true;
        this.DeleteMediaisShown = false;
      } else {
        this.DeleteisShown = false;
        this.DeleteMediaisShown = true;
      }
      this.getNotificationByMedia(this.pageNotification, this.sizeNotification);
    }, err =>{
      if(err.status = 404){
      this.router.navigateByUrl('/404')
      } else {
        this.msg.error(err.message);
      }
    });
    this.getModeration();
  }

  public deleteMedia(mediaId): void {
    this.mediaService.deleteMedia(mediaId).subscribe(() => {
      this.initMedia();
    });
  }

  /* public blockMedia(mediaId): void {
    this.mediaService.blockMedia(mediaId).subscribe((res) => {
      this.ngOnInit();
    });
  }

  public unblockMedia(media, mediaId): void {
    this.mediaService.unblockMedia(media, mediaId).subscribe((res) => {
      this.ngOnInit();
    });
  } */

  public toggleBlockMedia(): void {
    if (this.media.published) {
      this.mediaService.blockMedia(this.mediaId).subscribe((res) => {
        this.ngOnInit();
      });
    } else {
      this.mediaService
        .unblockMedia(this.media, this.mediaId)
        .subscribe((res) => {
          this.ngOnInit();
        });
    }
  }

  public deleteComment(commentId) {
    this.commentService.deleteComment(commentId).subscribe(() => {
      //console.log('comment deleted');
      this.ngOnInit();
    });
  }

  public getCommentByMedia(mediaId, value, page, size) {
    console.log('getComment: ', page, size);
    switch (value) {
      case 'all' /*visualizza tutti i commenti*/:
        this.commentService
          .filterCommentByMediaId(mediaId, page, size)
          .subscribe((comment) => {
            this.comments = comment.content;
            this.showedComments = this.comments;
            console.log('lista commenti: ', this.comments);
            this.totalComments = comment.totalElements;
          });

        break;

      case 'notified' /* visualizza i commenti segnalati ancora visibili*/:
        this.showedComments = [];
        for (var i = 0; i < this.comments.length; i++) {
          console.log(this.comments[i].notifications.length);

          if (this.comments[i].notifications.length > 0) {
            this.showedComments.push(this.comments[i]);
          }
        }
        this.totalComments = this.showedComments.length;
        //console.log("commenti segnalati",this.showedComments);
        break;

      case 'deleted' /* visualizza eliminati*/:
        this.showedComments = [];
        for (var i = 0; i < this.comments.length; i++) {
          if (this.comments[i].deletedOn != null) {
            this.showedComments.push(this.comments[i]);
            //console.log("commenti e,imitati",this.showedComments);
          }
        }
        this.totalComments = this.showedComments.length;
        //console.log("commenti",this.comments);
        break;
    }
  }

  goBack() {
    this.location.back();
  }

  getNotificationByMedia(pageN, sizeN) {
    // Get notification relative to this media
    this.mediaNotificationService
      .filterByMedia(this.mediaId, pageN, sizeN)
      .subscribe((notifications) => {
        this.notifications = notifications.content;
        console.log('this.notifications', this.notifications);
        this.totalNotifications = notifications.totalElements;
        console.log('this.notifications.length', this.notifications.length);
        if (this.notifications.length == 0) {
          this.showNotifications = false;
          console.log(this.showNotifications);
        } else {
          this.showNotifications = true;
        }
        console.log(this.showNotifications);
      });
  }

  changeStatus(notificationId: number) {
    this.notificationService
      .changeStatus(notificationId, MediaDetailsComponent.admin.userId)
      .subscribe(() => {
        DefaultLayoutComponent.uncheckednotification =
          DefaultLayoutComponent.uncheckednotification - 1;
        this.ngOnInit();
      });
  }

  changeStatusinfalse(notificationId: number) {
    this.notificationService
      .changeStatusinfalse(notificationId, MediaDetailsComponent.admin.userId)
      .subscribe(() => {
        DefaultLayoutComponent.uncheckednotification =
          DefaultLayoutComponent.uncheckednotification - 1;
        this.ngOnInit();
      });
  }

  public get currentUserValue(): User {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    console.log('this.currentUser', this.currentUserSubject.value.email);
    this.searchUserByEmail(this.currentUserSubject.value.email);
    return this.currentUserSubject.value;
  }

  public searchUserByEmail(email: String) {
    this.userService.searchByEmail(email).subscribe((userfound) => {
      MediaDetailsComponent.admin = userfound;
      console.log('UTENTE', MediaDetailsComponent.admin);
    });
  }

  goToUser() {
    this.router.navigate(['/userdetails', this.media.theUser.userId]);
  }

  getModeration() {
    this.mediaService.getModerations(this.mediaId).subscribe(
      (res) => {
        this.moderations = res;
      },
      (error) => {
        this.msg.error(error);
      }
    );
  }

  videoGoToTime(time: number, index: number) {
    let video: any = document.getElementById('mediaVideo');
    video.currentTime = time / 1000;
    this.moderationSelected = index;
  }
}

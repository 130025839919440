import { DecimalPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SERVER_BASE_URL } from '../constants';
import { changePasswordAdmin } from '../model/changePasswordAdmin';
import { User } from '../model/user';
import { AbstractService } from './abstractservice';


@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService<User, number>{


  constructor(http: HttpClient,private pipe: DecimalPipe){
    super(http);
    this.type = "user";
  }

  NewheaderOpt = new HttpHeaders({
    'Content-Type': 'multipart/form-data;boundary="boundary"',
    'Content-Disposition': 'form-data; name="user"',
    'Accept': '*/*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  });

  blockUser(idUser: number){
    return this.http.delete<any>(SERVER_BASE_URL+'user/block/' + idUser, {headers: this.headerOpt});
  }

  unblockUser(idUser: number){
    return this.http.post<any>(SERVER_BASE_URL+'user/unblock/' + idUser, {headers: this.headerOpt});
  }


  deleteUser(id: number): Observable<any> {
    return this.http.delete<any>(SERVER_BASE_URL + 'user/delete/' + id , {headers: this.headerOpt});
  }

  changeRole(idUser: number){
    return this.http.post<any>(SERVER_BASE_URL+'user/fromusertoadmin/' + idUser, {headers: this.headerOpt});
  }
  
  searchByName(name: string, page, size) {
    return this.http.get<any>(SERVER_BASE_URL+'user/findby/firstnamelastname/' + name + '/?page=' + page + '&size=' + size);
  }

  searchByEmail(email: String){
    return this.http.get<any>(SERVER_BASE_URL+'user/findbyemail/' + email);
  }

  findById(id: number){
    return this.http.get<any>(SERVER_BASE_URL+'user/findbyid/' + id);
  }

  // changePassword(t: changePasswordAdmin): Observable<any> {
  //   return this.http.put<any>(SERVER_BASE_URL + "user/mepass", t, {headers: this.NewheaderOpt});
  // }

  changePassword(t: changePasswordAdmin, f: FormData): Observable<any> {
    return this.http.put<any>(SERVER_BASE_URL + "user/me", f);
  }




}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';

import { CommonModule } from '@angular/common';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
// import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { MediaComponent } from './media/media.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { MediaDetailsComponent } from './media-details/media-details.component';
import { NotificationComponent } from './notification/notification.component';
import { CategoryComponent } from './category/category.component';
import { ContestComponent } from './contest/contest.component';
import { ContestDetailsComponent } from './contest-details/contest-details.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ConfigurationDetailsComponent } from './configuration-details/configuration-details.component';
import { AccessComponent } from './access/access.component';
import { AuthGuard } from './authguard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
// import { NotificationComponent } from './notifica/notification.component';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404',
    },
  },
  // {
  //   path: 'user',
  //   component: UserComponent
  // },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'recoverypassword',
    component: RecoveryPasswordComponent,
  },
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  //   data: {
  //     title: 'Register Page'
  //   }
  // },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'base',
        loadChildren: () =>
          import('./views/base/base.module').then((m) => m.BaseModule),
      },
      {
        path: 'utenti',
        component: UserComponent,
      },
      {
        path: 'media',
        component: MediaComponent,
      },
      {
        path: 'contest',
        component: ContestComponent,
      },
      {
        path: 'category',
        component: CategoryComponent,
      },
      {
        path: 'notification',
        component: NotificationComponent,
      },
      {
        path: 'adminpage',
        component: AdminPageComponent,
      },
      {
        path: 'configuration',
        component: ConfigurationComponent,
      },
      {
        path: 'access',
        component: AccessComponent,
      },
      {
        path: 'mediadetails/:mediaId',
        component: MediaDetailsComponent,
      },
      {
        path: 'userdetails/:userId',
        component: UserDetailsComponent,
      },
      {
        path: 'contestdetails/:contestId',
        component: ContestDetailsComponent,
      },
      {
        path: 'nuovoround/:contestId/:roundNumber',
        component: ContestDetailsComponent,
      },
      {
        path: 'rounddetails/:contestId/:roundId',
        component: ContestDetailsComponent,
      },
      {
        path: 'configurationdetails/:label/:id',
        component: ConfigurationDetailsComponent,
      },
      {
        path: 'buttons',
        loadChildren: () =>
          import('./views/buttons/buttons.module').then((m) => m.ButtonsModule),
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./views/chartjs/chartjs.module').then((m) => m.ChartJSModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./views/icons/icons.module').then((m) => m.IconsModule),
      },
      {
        path: 'theme',
        loadChildren: () =>
          import('./views/theme/theme.module').then((m) => m.ThemeModule),
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./views/widgets/widgets.module').then((m) => m.WidgetsModule),
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}

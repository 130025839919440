import { Component, Input } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { delay, repeat } from 'rxjs/operators';
import { LoginService } from '../../service/login.service';
import { AppComponent } from '../../app.component';
import { navItems } from '../../_nav';
import { LoginComponent } from '../../login/login.component';
import { User } from '../../model/user';
import { UserService } from '../../service/user.service';
import { MediaDetailService } from '../../service/media-detail.service';





@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  providers: [AppComponent]

})
export class DefaultLayoutComponent {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public sidebarMinimized = false;
  public navItems = navItems;

  notification: number;

  sub: Subscription;

  email: String;

  user: User;
  currentYear : Date;

  public static uncheckednotification: number;


  constructor(private loginService: LoginService, private userService: UserService, private notificationService: MediaDetailService) {
  }

  public ngOnInit() {
    this.findUncheckedNotification();
    this.currentUserValue;
    this.sub = interval(300000).subscribe((val) => { this.findUncheckedNotification(); });
    this.currentYear = new Date();
  }

  get staticUncheckednotification() {
    return DefaultLayoutComponent.uncheckednotification;
  }


  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.loginService.logout();
  }

  public get currentUserValue(): User {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
      // this.searchUserByEmail(this.currentUserSubject.value.email);
    return this.currentUserSubject.value;
  }

  public searchUserByEmail(email: String) {
    this.userService.searchByEmail(email).subscribe(userfound => {
      this.user = userfound;
      console.log("UTENTE", this.user);
    });
  }

  public findUncheckedNotification() {
    if (this.currentUserValue) { //se ho effettuato l'accesso
      this.notificationService.countNotification().subscribe(uncheckednotification => {
        DefaultLayoutComponent.uncheckednotification = uncheckednotification;

        console.log("numero", uncheckednotification);
      });

    }
  }
}

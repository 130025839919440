import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SERVER_BASE_URL } from '../constants';
import { Contest } from '../model/contest';
import { SortColumn, SortDirection } from '../sortable.directive';
import { AbstractService } from './abstractservice';

@Injectable({
  providedIn: 'root',
})
export class ContestService extends AbstractService<Contest, number> {
  constructor(http: HttpClient) {
    super(http);
    this.type = 'contest';
  }

  findOne(id: number): Observable<any> {
    return this.http.get<any>(
      SERVER_BASE_URL + this.type + '/detail/' + id + '?published=false'
    );
  }

  saveContest(t: Contest): Observable<any> {
    return this.http.post<any>(SERVER_BASE_URL + 'contest/newContest', t);
  }
  saveContestWithMedia(f: FormData): Observable<any> {
    return this.http.post<any>(SERVER_BASE_URL + 'contest/create', f);
  }
  updateContest(id, f: FormData): Observable<any> {
    return this.http.put<any>(SERVER_BASE_URL + 'contest/update/' + id, f);
  }
  searchContest(nome, page, size): Observable<any> {
    return this.http.get<any>(
      SERVER_BASE_URL +
        'contest/search/by-name/' +
        nome +
        '?page=' +
        page +
        '&size=' +
        size
    );
  }

  findSinging(published, page, size): Observable<any> {
    return this.http.get<any>(
      SERVER_BASE_URL +
        'contest/filterby/category/1?page=' +
        page +
        '&size=' +
        size +
        '&published=' +
        published +
        ''
    );
  }

  findRecitation(published, page, size): Observable<any> {
    return this.http.get<any>(
      SERVER_BASE_URL +
        'contest/filterby/category/2?page=' +
        page +
        '&size=' +
        size +
        '&published=' +
        published +
        ''
    );
  }

  findDirecting(published, page, size): Observable<any> {
    return this.http.get<any>(
      SERVER_BASE_URL +
        'contest/filterby/category/3?page=' +
        page +
        '&size=' +
        size +
        '&published=' +
        published +
        ''
    );
  }

  deleteContest(contestId: number): Observable<any> {
    return this.http.delete<any>(
      SERVER_BASE_URL + this.type + '/delete/' + contestId,
      { headers: this.headerOpt }
    );
  }
  restoreContest(contestId: number) {
    return this.http.post<any>(
      SERVER_BASE_URL + 'contest/restore/' + contestId,
      { headers: this.headerOpt }
    );
  }

  /* highlightedContest(contestId: number) {
    return this.http.post<any>(SERVER_BASE_URL+'contest/highlighted/' + contestId, {headers: this.headerOpt});
  }

  nothighlightedContest(contestId: number) {
    return this.http.post<any>(SERVER_BASE_URL+'contest/nothighlighted/' + contestId, {headers: this.headerOpt});
  } */

  publishedToggleContest(contestId: number) {
    return this.http.post<any>(
      SERVER_BASE_URL + 'contest/toggle/publish/' + contestId,
      { headers: this.headerOpt }
    );
  }
  highlightedToggleContest(contestId: number) {
    return this.http.post<any>(
      SERVER_BASE_URL + 'contest/toggle/xhighlighted/' + contestId,
      { headers: this.headerOpt }
    ).pipe(catchError(super.handleErrorOzapp));
  }
}

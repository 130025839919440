import { observable, Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Service } from './service';
import { SERVER_BASE_URL } from '../constants';
import { catchError } from 'rxjs/operators';

export abstract class AbstractService<T, ID> implements Service<T, ID> {
  type: String;
  type2: String;
  type3: String;

  headerOpt = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: '*/*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  });

  protected constructor(protected http: HttpClient) {}

  save(t: T): Observable<any> {
    return this.http.post<any>(SERVER_BASE_URL + this.type, t);
  }

  update(id: ID, t: T): Observable<T> {
    return this.http.put<T>(SERVER_BASE_URL + this.type + '/' + id, t, {
      headers: this.headerOpt,
    });
  }

  findOne(id: ID): Observable<T> {
    return this.http.get<T>(
      SERVER_BASE_URL + this.type + '/' + id + '?published=false'
    );
  }

  findAll(): Observable<any> {
    return this.http.get<any>(SERVER_BASE_URL + this.type);
  }

  findAllPaginate(page: number, size: number): Observable<any> {
    return this.http.get<any>(
      SERVER_BASE_URL + this.type + '/?page=' + page + '&size=' + size
    );
  }

  delete(id: ID): Observable<T> {
    return this.http.delete<T>(SERVER_BASE_URL + this.type + '/' + id, {
      headers: this.headerOpt,
    });
  }

  /* handleErrorOzapp(error:HttpErrorResponse):Observable<never> {
    return throwError(this.msgErrorOzapp(error));
    //return this.logErrorOzapp(error);
  } */

  aahandleErrorOzapp(error: HttpErrorResponse): Observable<never> {
    // return Observable.throw(error || 'Server Error');
    try {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        return throwError(error.error.message);
      } else {
        if (!error.error) {
          const msg = error.message;
          const status = error.status;
          return throwError(
            '<br/>Errore imprevisto, riprovare più tardi.' +
              '<br/>' +
              'error: '+ msg +
              '<br/>' +
              'status: ' +
              status
          );
        } else {
          const msg = error.error.error;
          const status = error.error.status;
          const path = error.error.path;
          return throwError(
            '<br/>Errore imprevisto, riprovare più tardi.' +
              '<br/>' +
              'error: '+ msg +
              '<br/>' +
              'status: ' +
              status +
              '<br/>' +
              'path: ' +
              path
          );
        }
      }
    } catch (error) {
      // return an ErrorObservable with a user-facing error message
      // return Observable.throw('Errore imprevisto, riprovare più tardi.');
      const msg = error.message;
      const status = error.status;
      return throwError(
        '<br/>Errore imprevisto, riprovare più tardi.' +
          '<br/>' +
          'error: '+ msg +
          '<br/>' +
          'status: ' +
          status
      );
    }
  }

  handleErrorOzapp(error: HttpErrorResponse) {
    console.error("Ozapp Error: ",error);
    return throwError("");
  }
}
